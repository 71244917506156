<template>
  <div>
    <b-overlay :show="isShowLoading">
      <b-card :title="$t('master.department.form')">

        <validation-observer ref="validateForm" #default="{invalid}">
          <b-form @submit.prevent="saveData">
            <b-row>
              <b-col md="6">
                <b-form-group :label="$t('master.department.name')">
                  <validation-provider #default="{ errors }" :name="$t('master.department.name')" rules="required">
                    <b-form-input v-model="departmentName" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="text-center">
                <b-button type="submit" variant="success" class="my-1" :disabled="isBtnDisabled">
                  <b-spinner small v-if="isBtnDisabled"/>
                  <feather-icon icon="SaveIcon" v-else/>
                  {{ $t('general.btnSubmit') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>

      </b-card>
    </b-overlay>
  </div>

</template>

<script>
import {BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BSpinner, BForm, BButton, BOverlay} from 'bootstrap-vue'
import {required} from '@validations'
import {DepartmentService} from "@/services"

const departmentService = new DepartmentService()

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BSpinner,
    BOverlay,
  },
  async created() {
    this.id = this.$route.params.id

    if(this.id) {
      await this.getData()
    }

    this.isShowLoading = false
  },
  data: () => ({
    isShowLoading: true,
    isBtnDisabled: false,
    id: '',
    departmentName: '',
  }),
  methods: {
    async getData() {
      let queryResult = await departmentService.getData(this.id)
      if(queryResult.isSuccess) {
        let {data} = queryResult
        let {result} = data
        this.departmentName = result.departmentName
      } else {
        this.$store.commit('main/setToastError', queryResult.data)
        await this.$router.push({name: 'pageMasterDepartment'})
      }
    },
    saveData() {
      this.$refs.validateForm.validate().then(async success => {
        if (success) {
          this.isBtnDisabled = true
          let actionData = {
            departmentName: this.departmentName
          }
          let saveResult

          if (this.id) {
            saveResult = await departmentService.updateData(this.id, actionData)
          } else {
            saveResult = await departmentService.createData(actionData)
          }

          this.isBtnDisabled = false

          if (saveResult.isSuccess) {
            this.$store.commit('main/setToastSuccess', {title: 'Save success', text: ''})
            await this.$router.push({name: 'pageMasterDepartment'})
          } else {
            this.$store.commit('main/setToastError', saveResult.data)
          }
        }
      })
    }
  }
}
</script>

<style>

</style>
